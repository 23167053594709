import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { AboutHalva } from "../components/aboutHalva"
import { FormPKW } from "../components/FormPKW"
import { NewFooter } from "../components/NewFooter"
import { BannerWithDescription, ShubaImg } from "../components/Banners/BannerWithDescription"
import { getPageData } from "../helpers/getPageData"
import { TwoChoice } from "../components/twoChoice"
import { Questions } from "../components/Questions"
import { Schedule } from "../components/Schedule"
import { QUESTIONS_FOR_SHUBA } from "../components/Questions/helpers"

import { PageData } from "../interfaces/pageProps"

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  const seoData = {
    title: "Акция Шубохранилище c Халвой  - выиграйте шубу при покупках по карте",
    description:
      "Участвуйте в розыгрыше Шубохранилище при совершении покупок от 500 рублей в любых магазинах по карте Халва. Принимайте участие и получите шанс выиграть стильную шубу, делая свои покупки еще более выгодными!",
  }

  return (
    <Layout seoData={seoData} noHeader noFooter noIndex={noIndex}>
      <NewHeader withoutHintAndCountDown />
      <BannerWithDescription Img={ShubaImg} orderNum="1" />
      <HowInstallmentWorks
        orderNum="2"
        variant="shuba"
        headTitle={
          <>
            Чем больше покупок, тем выше шанс на победу! <br />
            Как принять участие?
          </>
        }
      />
      <TwoChoice orderNum="3" />
      <AboutHalva orderNum="4" />
      <FormPKW
        title={
          <>
            Оформи карту «Халва» <br /> для участия в акции
          </>
        }
        dataLayerName="shortPersonalForm"
        formBtnText="Оформить карту"
        orderNum="5"
        emptyForm={false}
      />
      <Schedule orderNum="6" />
      <Questions questionList={QUESTIONS_FOR_SHUBA} orderNum="7" withButtons />
      <NewFooter ligal={ligal} orderNum="8" withSocialLinks withStoreLinks />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/shuba/") {
        url
        notIndex
        ligal {
          text
        }
      }
    }
  }
`
